import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo/Seo'
import Img from 'gatsby-image'

const PostTemplateDocs = ({ data }) => {
  return (
    <>
      <Layout>
        <SEO
          title={`toyreads - ${data.googleDocs.name}`}
          banner={data.googleDocs.cover.image.childImageSharp.original.src}
          desc={data.googleDocs.description}
          pathname={`/review${data.googleDocs.path}`}
          node={data.googleDocs}
          article
        />
        <Img
          style={{ marginBottom: '25px', marginTop: '54px' }}
          fluid={data.googleDocs.cover.image.childImageSharp.fluid}
        />
        <div
          className='content container mt-6 p-3'
          dangerouslySetInnerHTML={{
            __html: data.googleDocs.childMarkdownRemark.html
          }}
        />
      </Layout>
    </>
  )
}

export default PostTemplateDocs

export const pageQuery = graphql`
  query reviewFromDoc($slug: String) {
    googleDocs(path: { eq: $slug }) {
      description
      path
      name
      cover {
        image {
          childImageSharp {
            original {
              src
            }
            fluid(maxWidth: 1920, maxHeight: 650) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      childMarkdownRemark {
        html
      }
    }
  }
`
